<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" color="primary" dark>
        <v-icon left>mdi-movie-plus</v-icon>
        Novo Curso
      </v-btn>
    </template>
    <v-card class="v-card-profile">
      <v-app-bar color="primary" dark flat>
        <v-toolbar-title class="body-2">
          <!-- <v-spacer></v-spacer> -->
          Novo Curso
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <v-form v-model="valid" ref="form" lazy-validation>
          <!--v-model="valid" ref="form" lazy-validation -->
          <v-layout row wrap>
            <v-flex lg12 sm12>
              <v-text-field label="Título do curso" v-model="curso.titulo">
              </v-text-field>
            </v-flex>
            <v-flex lg12 sm12>
              <v-text-field label="Descrição" v-model="curso.descricao">
              </v-text-field>
            </v-flex>
            <v-flex lg4 sm12>
              <v-text-field label="Carga Horária" v-model="curso.duracao" persistent-hint hint="Carga horário em horas/aula">
              </v-text-field>
            </v-flex>
            <v-flex lg4 sm12>
              <v-text-field label="Duração" v-model="curso.permanencia" persistent-hint hint="Duração em semanas">
              </v-text-field>
            </v-flex>
            <v-flex lg4 sm12>
              <v-text-field label="Valor" v-model="curso.valor"> </v-text-field>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-3">
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click.native="cancelar()"
          >Cancelar</v-btn
        >
        <v-btn color="primary" @click.native="salvar()">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import toastr from "toastr";
import firebase from "firebase/app";
// Utilities
import { mapState } from "vuex";
export default {
  name: "CursoModal",
  data() {
    return {
      dialog: false,
      valid: true,
      password: null,
      curso: {
        descricao: "",
        disabled: false,
        duracao: 0,
        geral: "",
        permanencia: 0,
        thumbnail: "",
        titulo: "",
        valor: "",
      },
    };
  },
  computed: {
    // ...mapState(["userEdit"]),
  },
  methods: {
    salvar() {
      let curso = this.curso;
      const ref = "cursos/";
      firebase
        .database()
        .ref(ref)
        .push(curso)
        .then(() => {
          // Update successful.
          toastr.success("Curso Inserido com sucesso!", "MCS Assessoria e Treinamentos");
          this.dialog = false;
        })
        .catch((error) => {
          toastr.error(
            "Algo Inesperado aconteceu: " + error.message,
            "MCS Assessoria e Treinamentos"
          );
        });

      //Verificar a possibilidade de atualizar o curso nos usuários matriculados
    },
    cancelar() {
      this.dialog = false;
    },
  },
  created() {
    // console.log(this.userEdit)
  },
  mounted() {
    // console.log(process.env.VUE_APP_ROOT_API)
  },
};
</script>
